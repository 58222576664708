import { Box, Grid, Tooltip } from "@mui/material";
import SearchBar from "components/Members/SearchBar";
import BusinessCentralFilter from "./Filters";
import ProjectFilterChips from "./ProjectFilterChips";
import { noop } from "lodash";
import PropTypes from "prop-types";
import { StyledBox } from "utils/members";
import CSV from "assets/CSV.png";
import GiftIcon from "assets/giftboxicon.png";
import T from "T";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import AddIcon from "@mui/icons-material/Add";
import { GET_SIZE } from "utils/responsive";
import MISButton from "components/common/MISButton";

const TopBar = ({
  userRole,
  filters,
  isFilterEmpty,
  selectedProjects = [],
  searchInput = "",
  showOptions = false,
  showCancelIcon = false,
  searchTableData = {},
  handleChange = noop,
  onClick = noop,
  handleKeyChange = noop,
  onClickOutside = noop,
  handleAddProject = noop,
  handleChipChange = noop,
  onhandleFilterChange = noop,
  handleFilterSubmit = noop,
  handleFilterClose = noop,
}) => {
  const { isLg } = GET_SIZE();

  const handleGiftChange = () => {
    if (selectedProjects.length === 1 && selectedProjects.includes(T.GIFT)) {
      return;
    }

    const newStatus = selectedProjects.includes(T.GIFT)
      ? selectedProjects.filter((selectedProject) => selectedProject !== T.GIFT)
      : [T.GIFT];

    handleChipChange(newStatus);
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={isLg ? "space-between" : "center"}
      sx={{
        mt: 1,
        p: 1,
        backgroundColor: "background.card",
        "& .MuiOutlinedInput-input": {
          fontSize: 14,
        },
      }}
    >
      <Grid item md={2.5} xs={12}>
        <SearchBar
          records={searchTableData}
          searchInput={searchInput}
          showOptions={showOptions}
          showCancelIcon={showCancelIcon}
          onClick={onClick}
          handleKeyChange={handleKeyChange}
          handleChange={handleChange}
          onClickOutside={onClickOutside}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <ProjectFilterChips selectedProjects={selectedProjects} onChange={handleChipChange} />
      </Grid>
      <Grid item md={1.5} xs={12}>
        <BusinessCentralFilter
          userRole={userRole}
          isFilterEmpty={isFilterEmpty}
          filters={filters}
          selectedProjects={selectedProjects}
          onhandleFilterChange={onhandleFilterChange}
          handleFilterSubmit={handleFilterSubmit}
          handleFilterClose={handleFilterClose}
        />
      </Grid>
      <Grid item md={0.25} xs={2}>
        <Box display="flex" alignItems="center">
          <Tooltip title={T.GIFT} placement="top">
            <StyledBox
              onClick={handleGiftChange}
              component="img"
              sx={{ color: NETSMARTZ_THEME_COLOR }}
              src={GiftIcon}
              style={{ height: "25px", width: "25px" }}
            />
          </Tooltip>
        </Box>
      </Grid>
      <Grid item md={0.25} xs={2}>
        <Box display="flex" alignItems="center">
          <Tooltip title={T.CSV} placement="top">
            <StyledBox
              component="img"
              sx={{ color: NETSMARTZ_THEME_COLOR }}
              src={CSV}
              style={{ height: "40px", width: "40px" }}
            />
          </Tooltip>
        </Box>
      </Grid>
      <Grid md={2} xs={8} container justifyContent="flex-end">
        <MISButton size="medium" startIcon={<AddIcon sx={{ width: 19 }} />} onClick={() => handleAddProject()}>
          {T.ADD_NEW_PROJECT}
        </MISButton>
      </Grid>
    </Grid>
  );
};

TopBar.propTypes = {
  selectedProjects: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChipChange: PropTypes.func.isRequired,
};

export default TopBar;
