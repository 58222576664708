import { Children } from "react";
import { Grid, Typography } from "@mui/material";
import MISDatePicker from "components/common/MISDatePicker";
import MISTextField from "components/common/MISTextField";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { noop } from "lodash";
import T from "T";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";

const ProjectEstimate = ({
  id = "",
  totalTimeSpend = 0,
  projectEstimate = [],
  savedProjectEstimate = [],
  totalRevenueGenerated = 0,
  onHandleEstAddMore = noop,
  removeRow = noop,
  onHandleEstimateChange = noop,
  onHandleEstimateDateChange = noop,
}) => {
  const renderIcons = (index) => (
    <>
      {index === projectEstimate.length - 1 && (
        <AddCircleOutlineIcon
          onClick={() => {
            onHandleEstAddMore();
          }}
          sx={{
            mt: 3.5,
            cursor: "pointer",
            color: NETSMARTZ_THEME_COLOR,
          }}
        />
      )}
      {((id && index >= savedProjectEstimate.length && index !== 0) ||
        (!id && index > 0) ||
        (projectEstimate.length > 1 && index >= 0)) && (
        <RemoveCircleOutlineIcon
          onClick={() => {
            removeRow(index);
          }}
          sx={{
            mt: 3.5,
            cursor: "pointer",
            color: NETSMARTZ_THEME_COLOR,
          }}
        />
      )}
    </>
  );
  const totalOriginalHours = projectEstimate
    .map((item) => parseInt(item.originalTimeEstimate ? item.originalTimeEstimate : 0))
    .reduce((acc, item) => acc + item);
  return (
    <Grid container spacing={2}>
      <Grid md={12} sx={{ display: "flex", justifyContent: "space-around", px: 2 }}>
        <Grid
          md={3.5}
          sx={{
            display: "flex",
            justifyContent: "space-around",
            height: "90px",
            alignItems: "center",
            alignContent: "center",
            border: "1px solid #ccc",
            backgroundColor: "#3db76d",
            borderRadius: "10px",
            mt: 1,
          }}
        >
          <Typography fontSize={18} sx={{ fontWeight: "550", color: "#33333" }}>
            {"Total Time Spent (in hours)"}
          </Typography>
          <Typography fontSize={36} sx={{ fontWeight: "715", color: "#33333" }}>
            {parseInt(totalTimeSpend)}
          </Typography>
        </Grid>
        <Grid
          md={4}
          sx={{
            display: "flex",
            justifyContent: "space-around",
            height: "90px",
            alignItems: "center",
            alignContent: "center",
            border: "1px solid #ccc",
            backgroundColor: "#ffc312",
            borderRadius: "10px",
            mt: 1,
          }}
        >
          <Typography fontSize={18} sx={{ fontWeight: "550", color: "#33333" }}>
            {"Total Original Time Estimate (in hours)"}
          </Typography>
          <Typography fontSize={36} sx={{ fontWeight: "715", color: "#33333" }}>
            {totalOriginalHours}
          </Typography>
        </Grid>

        <Grid
          md={3.5}
          sx={{
            display: "flex",
            justifyContent: "space-around",
            height: "90px",
            alignItems: "center",
            alignContent: "center",
            border: "1px solid #ccc",
            backgroundColor: "#ffc312",
            borderRadius: "10px",
            mt: 1,
          }}
        >
          <Typography fontSize={18} sx={{ fontWeight: "550", color: "#33333" }}>
            {"Total Revenue Generated"}
          </Typography>
          <Typography fontSize={36} sx={{ fontWeight: "715", color: "#33333" }}>
            {totalRevenueGenerated}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography fontSize={16} fontWeight={600}>
          {T.PROJECT_ESTIMATE.toLocaleUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {Children.toArray(
          projectEstimate.map((data, index) => {
            return (
              <Grid container spacing={2} key={index} pt={index === 0 ? 0 : 0.5}>
                <Grid item md={11 / 3} xs={12}>
                  <MISDatePicker
                    label={T.START_DATE}
                    placeholder={T.START_DATE}
                    name="startDate"
                    value={projectEstimate[index]["startDate"]}
                    handleChange={(newValue, type) => onHandleEstimateDateChange(newValue, type, index)}
                  />
                </Grid>
                <Grid item md={11 / 3} xs={12}>
                  <MISTextField
                    label={T.ORIGINAL_TIME_ESITIMATE_IN_HRS}
                    fullWidth
                    placeholder={T.ORIGINAL_TIME_ESITIMATE_IN_HRS}
                    autoComplete="on"
                    size="small"
                    variant="outlined"
                    name="originalTimeEstimate"
                    value={projectEstimate[index]["originalTimeEstimate"]}
                    onChange={(e) => onHandleEstimateChange(e, index)}
                  />
                </Grid>
                <Grid item md={11 / 3} xs={12}>
                  <MISTextField
                    label={T.TOTAL_COST_IN_RUPEES}
                    fullWidth
                    placeholder={T.TOTAL_COST_IN_RUPEES}
                    autoComplete="on"
                    size="small"
                    variant="outlined"
                    name="totalCost"
                    value={projectEstimate[index]["totalCost"]}
                    onChange={(e) => onHandleEstimateChange(e, index)}
                  />
                </Grid>
                <Grid item md={1} xs={12}>
                  {renderIcons(index)}
                </Grid>
              </Grid>
            );
          }),
        )}
      </Grid>
    </Grid>
  );
};

export default ProjectEstimate;
